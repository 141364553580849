import React, { useState } from "react";
import Back from "../common/back/Back";
import "./contact.css";
import GoToTopButton from "../home/GoToTopButton";
import axios from "axios";

const Contact = () => {
  const [email, setEmails] = useState("");
  const [name, setNames] = useState("");
  var [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleEmailsChange = (event) => {
    setEmails(event.target.value);
  };

  const handleNamesChange = (event) => {
    setNames(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    message =
      "Message from " +
      name +
      "\nEmail Address: " +
      email +
      "\nMessage: " +
      message;

    try {
      const response = await axios.post("/api/sendEmail", {
        email,
        name,
        message,
        subject,
      });

      console.log(response.data);
      setSuccess(true);
    } catch (error) {
      console.error("Error occurred:", error);
      setError("An error occurred while sending the email.");
    } finally {
      setLoading(false);
    }
  };

  const map =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d917.9074156752449!2d72.51360196958724!3d23.037366098697735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b2a1c6cd8eb%3A0xee1b07c828979a1c!2sB%20902%2C%20nri%20tower!5e0!3m2!1sen!2sin!4v1715583189439!5m2!1sen!2sin" width="600" height="20950" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ';
  return (
    <>
      <Back title="Contact us" />
      <section className="contacts padding">
        <div className="container shadow flexSB">
          <div className="left row">
            <iframe src={map}></iframe>
          </div>
          <div className="right row">
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>
                  A-304, Third Floor, N. R. I. Tower, Opp. Hotel Pride,
                  Satellite, Judges Bunglow Rd, Ahmedabad, Gujarat - 380054.
                </p>
              </div>
              <div className="box">
                <h4>EMAIL:</h4>
                <p>social@swimple.in</p>
              </div>
              <div className="box">
                <h4>PHONE:</h4>
                <p> +918861873888</p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="flexSB">
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={handleNamesChange}
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailsChange}
                />
              </div>
              <input
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={handleSubjectChange}
              />
              <textarea
                cols="30"
                rows="10"
                placeholder="Create a message here..."
                value={message}
                onChange={handleMessageChange}
              ></textarea>
              <button type="submit" disabled={loading} className="primary-btn">
                SEND MESSAGE
              </button>
              {loading && <p>Loading...</p>}
              {error && <p>Error: {error}</p>}
              {success && <p>Email sent successfully!</p>}
            </form>

            <h3>Follow us here</h3>
            <span>
              FACEBOOK TWITTER
              <a
                href="https://www.instagram.com/swimplesportlab/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                INSTAGRAM{" "}
              </a>
              DRIBBBLE
            </span>
          </div>
        </div>
      </section>
      <GoToTopButton />
    </>
  );
};

export default Contact;
