import React from "react";
import TeamCard from "../team/TeamCard";
import "../team/team.css";
import "../about/about.css";
import Heading from "../common/heading/Heading";
import { team } from "../../dummydata";

const Team = () => {
  return (
    <>
      <section id="Hteam" className="team padding">
        <Heading title="Swimple Team" />
        <div className="container grid">
          {team.map((val) => (
            <div className="items shadow">
              <div className="img">
                <img src={val.cover} alt="" />
              </div>
              <div className="details">
                <h2>{val.name}</h2>
                <p>{val.work2}</p>
                <p className="desc">{val.work}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Team;
