import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Head = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(false); // Close the mobile navigation menu
  };

  const handleSmoothScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <section className="head">
        <div className="container flexSB">
          <div onClick={handleClick} className="logo">
            <Link to="/">
              <img src="../../../images/logo.png" className="logo" alt="" />
            </Link>
          </div>

          <div className="social">
            <i className="fab fa-facebook-f icon"></i>
            <a
              href="https://www.instagram.com/swimplesportlab/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram icon"></i>
            </a>
            <i className="fab fa-twitter icon"></i>
            <i className="fab fa-youtube icon"></i>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
