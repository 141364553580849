import React, { useState } from "react";
import "./footer.css";
import axios from "axios";

const Footer = () => {
  const [email, setEmails] = useState("");
  const [name, setNames] = useState("");
  var [message, setMessage] = useState("");
  const [subject, setSubject] = useState("Get Updates From Swimple!!");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleEmailsChange = (event) => {
    setEmails(event.target.value);
  };

  const handleNamesChange = (event) => {
    setNames(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    message="Hello "+name+" !"+"Thank You for Joining Us! \nYou're now part of our community! \nExpect exciting updates and exclusive content in your inbox soon!"

    try {
      const response = await axios.post("/api/sendEmail", {
        email,
        name,
        subject,
        message,
      });
      console.log(response.data);
      setSuccess(true);
    } catch (error) {
      console.error("Error occurred:", error);
      setError("An error occurred while sending the email.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section id="Hcontact" className="newletter">
        <div className="container flexSB">
          <div className="left row">
            <h1>Get in touch - Stay tuned and get the latest update</h1>
            <span>
              To facilitate and expedite athletes' path to performance
              excellence in sports by harnessing innovation and technology for
              enhanced learning experiences
            </span>
          </div>

          <div className="right ">
            <div>
              <form onSubmit={handleSubmit}>
                <input
                  className="name"
                  type="text"
                  placeholder="Name"
                  onChange={handleNamesChange}
                  value={name}
                />
                <input
                  className="email"
                  type="email"
                  placeholder="Enter email address"
                  onChange={handleEmailsChange}
                  value={email}
                />
                <button type="submit" disabled={loading}>
                  <i className="fa fa-paper-plane"></i>
                </button>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                {success && <p>Email sent successfully!</p>}
                
                <div></div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container ">
          <div className="box logo">
            <div className="logo">
              <img src="../../../images/logo.png" className="logo" alt="" />
            </div>
            <p>We are engineers with a deep passion for sports</p>

            <i className="fab fa-facebook-f icon"></i>
            <i className="fab fa-twitter icon"></i>
            <a
              href="https://www.instagram.com/swimplesportlab/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram icon"></i>
            </a>
          </div>
          <div className="box link">
            <h3>Explore</h3>
            <ul>
              <li>About Us</li>
              <li>Services</li>
              <li>Courses</li>
              <li>Blog</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className=" box link">
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Pricing</li>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
            </ul>
          </div>

          <div className="box last">
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className="fa fa-map"></i>
                A-304, Third Floor, N. R. I. Tower, Opp. Hotel Pride, Satellite,
                Judges Bunglow Rd, Ahmedabad, Gujarat - 380054.
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                +918861873888
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                social@swimple.in
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>
          Copyright ©2024 All rights reserved | This website is made with{" "}
          <i className="fa fa-heart"></i> by Swimple
        </p>
      </div>
    </>
  );
};

export default Footer;
